import {RestService} from './rest.service';
import {StorageService} from './storage.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../global/app';
import {ObjectCompare} from '../helpers/object-utils';
import {IUser, User} from "../interfaces/user";



class AccountServiceController extends RestService {
    public modalOpened = false;
    private account$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
    public account = this.account$.asObservable();
    private payedSubscribed$: Subject<string> = new Subject<string>();
    public payedSubscribed = this.payedSubscribed$.asObservable();

    constructor() {
        super();
        this.setApi(environment.REST_API);
        let account = StorageService.get('account');
        account = (account) ? new User().deserialize(account) : null;
        this.account$.next(account);
    }


    public getAccount() {
        return this.account$.getValue();
    }
    public id() {
        return this.getAccount()?._id;
    }

    public isLoggedIn(id: string) {
        if (id){
            return (this.id() === id);
        }
        return false
    }



    public set(user: IUser, assign = false) {
        if (assign) {
            const oldUser = this.account$.getValue();
            if (oldUser) {
                user = Object.assign(oldUser, user);
            }
        }
        const user$ = new User().deserialize(user);
        if (!ObjectCompare(this.account$.getValue(), user$)) {
            this.account$.next(user$);
        }
        StorageService.set('account', user$);
    }

    public remove() {
        this.account$.next(null);
        StorageService.remove('filter')
        StorageService.remove('account');
    }
    public hasRole(role:string){
        const account = StorageService.get('account');
        if (account) {
            return account?.roles?.includes(role);
        } else {
            return false;
        }
    }























}

export const AccountService = new AccountServiceController();