
import {Deserializable} from './deserialize';


export interface ICoupon {
  _id?: string;
  name?: string;
  shopId?: string;
  code?: string;
  amount?: number;
  status?: string;
  expirationDate?: string;
  transactionIds?: string[];
}

export class Coupon implements ICoupon, Deserializable {
  _id?: string;
  name?: string;
  shopId?: string;
  code?: string;
  amount?: number;
  status?: string;
  expirationDate?: string;
  transactionIds?: string[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

