import {RestService} from './rest.service';
import {StorageService} from './storage.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../global/app';
import {ObjectCompare} from '../helpers/object-utils';
import {Coupon, ICoupon} from "../interfaces/coupon";



class CouponServiceController extends RestService {
    public modalOpened = false;
    private coupon$: BehaviorSubject<ICoupon> = new BehaviorSubject<ICoupon>(null);
    public coupon = this.coupon$.asObservable();
    private payedSubscribed$: Subject<string> = new Subject<string>();
    public payedSubscribed = this.payedSubscribed$.asObservable();

    constructor() {
        super();
        this.setApi(environment.REST_API);
        let coupon = StorageService.get('coupon');
        coupon = (coupon) ? new Coupon().deserialize(coupon) : null;
        this.coupon$.next(coupon);
    }


    public getCoupon() {
        return this.coupon$.getValue();
    }
    public id() {
        return this.getCoupon()?._id;
    }

    public isLoggedIn(id: string) {
        if (id){
            return (this.id() === id);
        }
        return false
    }
    public set(coupon: ICoupon, assign = false) {
        if (assign) {
            const oldCoupon = this.coupon$.getValue();
            if (oldCoupon) {
                coupon = Object.assign(oldCoupon, coupon);
            }
        }
        const coupon$$ = new Coupon().deserialize(coupon);
        if (!ObjectCompare(this.coupon$.getValue(), coupon$$)) {
            this.coupon$.next(coupon$$);
        }
        StorageService.set('coupon', coupon$$);
    }

    public remove() {
        this.coupon$.next(null);
        StorageService.remove('coupon');
    }

}

export const CouponService = new CouponServiceController();